@import '@fontsource/inter/index.css';
@import '@fontsource/manrope/index.css';

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

/* Общий стиль */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body.noScroll {
  overflow: hidden;
}

body.noScrollFilter {
  overflow: hidden;
}

h1,
h2,
h3 {
  margin: 0;
  padding: 0;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

body {
  font-family: 'Manrope', Arial, sans-serif;
}

/* Мобильная версия */
@media screen and (min-width: 300px) {
  .header__menu {
    display: none;
  }

  .header__nav {
    position: relative;
    width: 100%;
    max-width: 358px;
    margin: 0 auto;
  }

  body.noScroll .header__background--for-menu {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: #1369A2;
    height: 100000vh;
    opacity: 50%;
  }

  body.noScrollFilter .header__background--for-menu {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: #1369A2;
    height: 180vh;
    opacity: 50%;
  }

  .header__navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 358px;
    margin: 0 auto;
    background: #2DA0D4;
    background-image: url('../assets/icon/mobheader.svg');
    background-repeat: no-repeat;
    background-size: cover;
    height: 66px;
    border-radius: 20px;
    margin-top: 5px;
    z-index: 2;
    position: fixed;
    top: 0;
  }

  .header__logo {
    font-family: 'Inter', Arial, sans-serif;
    font-size: 13px;
    font-weight: bold;
    font-style: italic;
    padding-left: 11px;
    color: #fff;
  }

  .header__menu.show {
    position: fixed;
    top: 5px;
    z-index: 1;
    background-color: #32AADD;
    width: 100%;
    max-width: 358px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: baseline;
    height: 98vh;
    border-radius: 20px;
  }

  .header__menu-list {
    margin-top: 116px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding-left: 11px;
  }

  .header__menu-button-list {
    max-width: 358px;
    margin: 0 auto;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding: 0 11px;
  }

  .header__menu-item {
    padding-bottom: 20px;
    font-size: 36px;
    font-weight: 500;
    line-height: 100%;
    color: #fff;
    align-items: baseline;
  }

  .header__menu-button {
    background: #253D7A;
    width: 100%;
    text-align: center;
    padding: 10px 0;
    color: #fff;
    border-radius: 43px;
    font-size: 20px;
    margin-bottom: 13px;
  }

  .hamburger {
    cursor: pointer;
    border: none;
    outline: none;
    background: transparent;
    display: block;
    padding: 12px;
    transition: all 0.3s ease;
  }

  .hamburger span {
    display: block;
    background: #fff;
    width: 25px;
    height: 3px;
    margin-bottom: 2px;
    transition: all 0.3s ease;
  }

  .hamburger.active span:nth-child(1) {
    transform: rotate(45deg) translate(0px, 7px);
  }

  .hamburger.active span:nth-child(2) {
    opacity: 0;
  }

  .hamburger.active span:nth-child(3) {
    transform: rotate(-45deg) translate(0px, -7px);
  }

  .intro__wrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: column-reverse;
    width: 100%;
    max-width: 358px;
    margin: 0 auto;
    align-items: center;
    background-image: url("../assets/icon/intromob.svg");
    background-size: contain;
    background-repeat: no-repeat;
    margin-top: 5px;
  }

  .intro__mini-wrapper-two {
    margin-top: 116px;
    background-color: #30A6DA;
    border-radius: 10px;
  }

  .intro__number-list {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 222px;
    padding: 10px 29px 5px 19px;
  }

  .intro__number-item {
    width: 100%;
    max-width: 88px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .intro__number-title {
    font-size: 10px;
    font-weight: 400;
    color: #fff;
  }

  .intro__number {
    font-weight: bold;
    color: #253D7A;
    font-size: 35px;
  }

  .intro__number-item--one {
    width: 72px;
    margin-right: 39px;
  }

  .intro__mini-wrapper-one {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .intro__title {
    padding-top: 55px;
    font-size: 26px;
    line-height: 118.1%;
    font-weight: 800;
    color: #fff;
    text-align: center;
    width: 100%;
    max-width: 232px;
  }

  .intro__description {
    padding-top: 22px;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    width: 100%;
    max-width: 241px;
    text-align: center;
    line-height: 125.8%;
  }

  .intro__button-wrapper {
    padding-top: 84px;
    padding-bottom: 123px;
    max-width: 268px;
    width: 100%;
    margin: 0 auto;
  }

  .intro__button {
    color: #fff;
    font-weight: 500;
    font-size: 20px;
    background: #253D7A;
    border-radius: 67px;
    margin-bottom: 11px;
    text-align: center;
    padding: 12px 0;
  }

  .introCard {
    margin-top: -16px;
  }

  .introCard__wrapper {
    width: 100%;
    max-width: 358px;
    margin: 0 auto;
  }

  .introCard__list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
  }

  .introCard__item {
    border: 1px solid #31A7DB;
    border-radius: 26px;
    padding: 31px 36px 27px 11px;
    margin-bottom: 15px;
  }

  .introCard__icon {
    width: 50px;
    height: 50px;
    background-size: contain;
    background-repeat: no-repeat;
    margin-bottom: 15px;
  }

  .introCard__item-one {
    background-image: url('../assets/icon/introCardOne.svg');
    background-size: contain;
    background-repeat: no-repeat;
  }

  .introCard__item-two {
    background-image: url('../assets/icon/introCardTwo.svg');
    background-size: contain;
    background-repeat: no-repeat;
  }

  .introCard__item-three {
    background-image: url('../assets/icon/introCardThree.svg');
    background-size: contain;
    background-repeat: no-repeat;
  }

  .introCard__item-one .introCard__icon {
    background-image: url('../assets/icon/documenticon.svg');
  }

  .introCard__item-two .introCard__icon {
    background-image: url('../assets/icon/personalydocumenticon.svg');
  }

  .introCard__item-three .introCard__icon {
    background-image: url('../assets/icon/guardicon.svg');
  }

  .introCard__title {
    font-size: 22px;
    font-weight: 600;
    color: #000;
    margin-bottom: 5px;
  }

  .introCard__description {
    font-size: 16px;
    font-weight: 500;
  }

  .welkom {
    margin-top: 90px;
  }

  .welkom__wrapper {
    width: 100%;
    max-width: 358px;
    margin: 0 auto;
    padding-left: 11px;
  }

  .welkom__title {
    font-size: 26px;
    font-weight: 800;
    padding-bottom: 20px;
  }

  .welkom__copiright {
    font-weight: 500;
    font-size: 16px;
    padding-bottom: 20px;
  }

  .welkom__logo {
    width: 100%;
    background-image: url('../assets/icon/welkomlogo.svg');
    background-repeat: no-repeat;
    background-size: contain;
    height: 130px;
  }

  .opdrachtgevers__wrapper {
    width: 100%;
    max-width: 358px;
    margin: 0 auto;
    margin-top: 100px;
  }

  .opdrachtgevers__title {
    font-size: 26px;
    font-weight: 800;
    color: #000;
    margin-bottom: 45px;
  }

  .opdrachtgevers__list {
    margin-bottom: 40px;
  }

  .opdrachtgevers__item {
    background: #32AADD;
    border-radius: 27px;
    margin-bottom: 20px;
    padding: 22px 53px 284px 20px;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .opdrachtgevers__item-one {
    background-image: url('../assets/icon/opdrachtgeversmobone.svg');
  }

  .opdrachtgevers__item-two {
    background-image: url('../assets/icon/opdrachtgeversmobtwo.svg');
  }

  .opdrachtgevers__item-three {
    background-image: url('../assets/icon/opdrachtgeversmobthree.svg');
  }

  .opdrachtgevers__item-four {
    background-image: url('../assets/icon/opdrachtgeversmobfour.svg');
  }

  .beveiligers__item-one {
    background-image: url('../assets/icon/beveiligersonebg.svg');
  }

  .beveiligers__item-two {
    background-image: url('../assets/icon/beveiligerstwobg.svg');
  }

  .beveiligers__item-three {
    background-image: url('../assets/icon/beveiligersthreebg.svg');
  }

  .beveiligers__item-four {
    background-image: url('../assets/icon/beveiligersfourbg.svg');
  }

  .opdrachtgevers__list-title {
    font-size: 110px;
    color: #fff;
    font-weight: 700;
    margin-bottom: 25px;
  }

  .opdrachtgevers__list-copiright {
    font-size: 25px;
    font-weight: 700;
    margin-bottom: 17px;
    color: #fff;
  }

  .opdrachtgevers__list-text {
    font-size: 16px;
    font-weight: 500;
    color: #fff;
  }

  .opdrachtgevers__button {
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    color: #fff;
    background: #253D7A;
    border-radius: 61px;
    padding: 12px 0;
  }

  .support {
    margin-top: 135px;
    margin-bottom: 118px;
  }

  .support__wrapper {
    width: 100%;
    max-width: 358px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    background-image: url('../assets/icon/supportmob.svg');
    background-size: contain;
    background-repeat: no-repeat;
    padding: 42px 47px 45px 47px;
  }

  .support__title {
    font-size: 26px;
    color: #fff;
    font-weight: 900;
    text-align: center;
    margin-bottom: 11px;
  }

  .support__description {
    font-size: 18px;
    color: #fff;
    font-weight: 400;
    text-align: center;
    margin-bottom: 29px;
  }

  .support__button {
    color: #fff;
    background: #253D7A;
    border-radius: 61px;
    padding: 12px 29px;
  }

  .footer__wrapper {
    border-top: 3px solid #32AADD;
  }

  .footer__logo-wrapper {
    width: 100%;
    max-width: 358px;
    margin: 0 auto;
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 54px;
    margin-bottom: 68px;
  }

  .registration__common-wrapper {
    background: linear-gradient(to right, #00B1E4, #0D5B95);
    border-radius: 30px 30px 0 0;
  }

  .registration__input-error {
    position: absolute;
    right: 0;
    top: 14px;
    font-size: 16px;
    color: #FF7676;
  }

  .registration__input-password-error {
    position: absolute;
    left: 0;
    bottom: -64px;
    font-size: 16px;
    color: #FF7676;
    max-width: 212px;
  }

  .registration__input-error {
    position: absolute;
    left: 0;
    top: 194px;
    font-size: 16px;
    color: #FF7676;
  }

  .registration__input-error-border {
    border-color: #FF7676 !important;
  }

  .registration__common-bg-wrapper {
    width: 100%;
    max-width: 358px;
    margin: 0 auto;
    padding-top: 27px;
    padding-bottom: 50px;
  }

  .registration__logo-wrapper {
    width: 100%;
    max-width: 358px;
    margin: 0 auto;
    margin-top: 28px;
    margin-bottom: 28px;
  }

  .registration__logo {
    color: #253D7A;
    font-size: 18px;
    font-weight: 800;
    font-style: italic;
    font-family: 'Inter', Arial, sans-serif;
    text-align: center;
  }

  .registration__mini--wrapper .intro__mini-wrapper-two {
    margin-top: 0;
  }

  .registration__mini--wrapper {
    max-width: 222px;
  }

  .registration__big-wrapper {
    margin-top: 35px;
  }

  .registration__big-title {
    font-size: 26px;
    color: #fff;
    font-weight: 800;
    margin-bottom: 10px;
  }

  .registration__tabs-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 253px;
    margin-bottom: 22px;
  }

  .registration__tabs {
    color: #fff;
    font-size: 20px;
    font-weight: 300;
    cursor: pointer;
  }

  .registration__tabs.active {
    font-weight: 500;
    border-bottom: 6px solid #253D7A;
  }

  .registration__input-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    margin-bottom: 98px;
  }

  .registration__input {
    width: 100%;
    background: transparent;
    border-radius: 6px;
    outline: none;
    height: 52px;
    padding-left: 10px;
    font-family: 'Manrope', Arial, sans-serif;
    border: 1px solid #EDF2F7;
    margin-bottom: 15px;
    color: #fff;
  }

  .registration__input::-webkit-input-placeholder,
  .registration__input::-webkit-input-placeholder {
    color: #D8D8D8;
  }

  .registration__button {
    font-size: 20px;
    font-weight: 500;
    color: #fff;
    background: #253D7A;
    border-radius: 61px;
    width: 100%;
    text-align: center;
    padding: 12px 0;
    outline: none;
    border: none;
    cursor: pointer;
  }

  .registration__button:disabled {
    background: #cdcdcd;
    opacity: 0.6;
    /* Прозрачность */
    cursor: not-allowed;
    /* Курсор "неактивный" */
  }

  .registration__copiright {
    text-align: center;
    margin-top: 15px;
    font-size: 16px;
    color: #fff;
    padding-bottom: 100px;
  }

  .registration__copiright span {
    color: #253D7A;
  }

  .registration__input-mini-wrapper {
    width: 100%;
    position: relative;
  }

  .registration__input-password-vision {
    position: absolute;
    right: 13px;
    top: 18px;
    width: 16px;
    height: 16px;
    background-image: url('../assets/icon/eye.svg');
  }

  .qwestFilter__wrapper {
    width: 100%;
    max-width: 358px;
    margin: 0 auto;
    margin-top: 80px;
  }

  .qwestFilter__click-wrapper {
    border-radius: 20px;
    background-color: #EDF2F7;
    height: 62px;
    position: relative;
    display: flex;
    z-index: 0;
  }

  .qwestFilter__click-items {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 115px;
    width: 100%;
    padding-left: 14px;
  }

  .qwestFilter__mini-wrapper {
    background: #FFFFFF;
    border-radius: 30px;
    position: relative;
    z-index: 0;
    display: none;
    padding: 25px 22px;
    margin-top: 13px;
  }

  .noScrollFilter .qwestFilter__mini-wrapper {
    display: block;
  }

  .qwestFilter__label-wrapper {
    margin-bottom: 20px;
  }

  .qwestFilter__label-wrapper label input[type="checkbox"] {
    -webkit-appearance: none;
    appearance: none;
    border: 1px solid #33ADE0;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    background-color: #fff;
    outline: none;
    transition: all 0.2s ease-in-out;
    margin-right: 7px;
  }

  .qwestFilter__label-wrapper label input[type="checkbox"]:checked {
    background-color: #33ADE0;
    box-shadow: inset 0 0 0 3px #fff;
  }

  .qwestFilter__label-wrapper label span {
    font-size: 16px;
    color: #000;
    font-weight: 300;
  }

  .qwestFilter__label-wrapper label {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }

  .qwestFilter__label-wrapper-bold label span,
  .qwestFilter__label-wrapper-search label span {
    font-weight: 700;
  }

  .qwestFilter__label-wrapper-search label {
    flex-direction: column;
    align-items: unset;
    position: relative;
  }

  .qwestFilter__label-wrapper-search label input {
    height: 38px;
    width: 100%;
    background: #EDF2F7;
    border: none;
    outline: none;
    border-radius: 8px;
    margin-top: 5px;
    color: #000;
    padding-left: 30px;
    font-size: 16px;
    font-family: 'Manrope', Arial, sans-serif;
  }

  .qwestFilter__label-spacebetween {
    display: flex;
  }

  .qwestFilter__label-spacebetween label {
    margin-right: 20px;
  }

  .qwestFilter__label-wrapper h3 {
    margin-bottom: 10px;
  }

  .qwestFilter__label-wrapper-search label img {
    position: absolute;
    top: 38px;
    left: 10px;
    width: 16px;
    height: 16px;
  }

  .qwestFilter__outuput-text {
    font-size: 16px;
    font-weight: 700;
  }

  .qwestFilter__reset-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
  }

  .qwestFilter__reset-wrapper img {
    margin-right: 10px;
  }

  .qwestFilter__submit-button {
    width: 100%;
    font-size: 20px;
    border: none;
    outline: none;
    background: #253D7A;
    color: #fff;
    border-radius: 61px;
    padding: 12px 0;
  }

  .qwestion__wrapper {
    width: 100%;
    max-width: 358px;
    margin: 0 auto;
  }

  .qwestion__list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
  }

  .qwestion__item {
    width: 100%;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin-bottom: 20px;
  }

  .qwestion__item-header img {
    background: #32AADD;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid #fff;
    margin-right: 10px;
  }

  .qwestion__item-header {
    display: flex;
    align-items: center;
    padding: 19px 16px;
    background: #32AADD;
    border-radius: 10px 10px 0 0;
  }

  .qwestion__title-wrapper {
    display: flex;
    flex-direction: column;
    margin-right: 85px;
  }

  .qwestion__title {
    font-size: 16px;
    font-weight: 600;
    color: #fff;
  }

  .qwestion__copiright {
    font-size: 16px;
    font-weight: 400;
    color: #fff;
  }

  .qwestion__date-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .qwestion__date-copiright {
    font-size: 12px;
    font-weight: 300;
    color: #fff;
  }

  .qwestion__date {
    font-size: 16px;
    font-weight: 700;
    color: #fff;
  }

  .qwestion__item-body {
    border-radius: 10px;
    background: #fff;
    margin-top: -10px;
    padding: 13px 16px 10px 16px;
  }

  .qwestion__item-name {
    font-size: 20px;
    font-weight: 600;
    color: #000;
    margin-bottom: 5px;
  }

  .qwestion__item-price-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 5px 26px 5px 10px;
    background: #EDF2F7;
    border-radius: 10px;
    color: #253D7A;
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 5px;
  }

  .qwestion__item-text {
    margin-bottom: 3px;
    font-size: 14px;
    font-weight: 300;
  }

  .qwestion__item-copiright {
    font-weight: 700;
  }

  .qwestion__item-button-wrapper {
    margin-top: 7px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
    color: #253D7A;
    font-weight: 700;
  }

  .qwestion__item-button {
    position: relative;
    cursor: pointer;
  }

  .qwestion__item-button.mail {
    padding-left: 33px;
  }

  .qwestion__item-button.mail::before {
    content: '';
    position: absolute;
    width: 22px;
    height: 20px;
    top: 4px;
    left: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('../assets/icon/mail.svg');
  }

  .qwestion__item-button.link::before {
    content: '';
    position: absolute;
    width: 14px;
    height: 13px;
    top: 9px;
    left: -23px;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('../assets/icon/link.svg');
  }

  .noScrollFilter .qwestFilter__mini-wrapper {
    overflow-y: scroll;
    height: 80vh;
  }

  .noScrollFilter .qwestFilter__mini-wrapper::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  .noScrollFilter .qwestFilter__mini-wrapper::-webkit-scrollbar-track {
    background: transparent;
    /* Уберите фон полосы скролла */
  }

  .noScrollFilter .qwestFilter__mini-wrapper::-webkit-scrollbar-thumb {
    background: transparent;
    /* Уберите цвет полосы скролла */
  }

  .qwestion__item-more-button {
    font-size: 20px;
    background: #fff;
    font-weight: 400;
    padding: 12px 80px;
    text-align: center;
    justify-content: center;
    display: flex;
    width: 100%;
    align-items: center;
    max-width: 268px;
    margin: 0 auto;
    margin-bottom: 80px;
    margin-top: 20px;
    border: 1px solid #253D7A;
    color: #253D7A;
    border-radius: 61px;
    cursor: pointer;
  }

  .thanks {
    width: 100%;
    background-image: url('../assets/icon/thxbg.svg');
    background-repeat: no-repeat;
    background-size: cover;
  }

  .thanks__wrapper {
    width: 100%;
    max-width: 358px;
    margin: 0 auto;
    padding-top: 150px;
    padding-bottom: 150px;
  }

  .thanks__wrapper img {
    width: 300px;
    margin: 0 auto;
    display: flex;
    height: 33px;
  }

  .thanks__title {
    font-size: 20px;
    text-align: center;
    padding-top: 30px;
    color: #fff;
  }

  .thanks__copiright {
    font-size: 15px;
    text-align: center;
    padding-top: 15px;
    color: #fff;
  }

  .intro__mini-wrapper-thanks {
    max-width: 222px;
    margin: 0 auto;
    margin-top: 50px;
  }

  .thanks__wrapper-policy {
    width: 100%;
    max-width: 358px;
    margin: 0 auto;
    margin-top: 60px;
  }
  
  .loader__wrapper {
     height:60px;
     width:60px;
    margin:0 auto;
   position:relative;
    position: absolute;
    top:50%;
    left:50%;
     -webkit-animation: rotation 1s infinite linear;
     -moz-animation: rotation 1s infinite linear;
     -o-animation: rotation 1s infinite linear;
     animation: rotation 1s infinite linear;
     border:6px solid rgba(0,0,0,.2);
     border-radius:100%;
     margin-left: -30px;
  }
  
  .loader__wrapper:before {
     content:"";
     display:block;
     position:absolute;
     left:-6px;
     top:-6px;
     height:100%;
     width:100%;
     border-top:6px solid #253D7A;
     border-left:6px solid transparent;
     border-bottom:6px solid transparent;
     border-right:6px solid transparent;
     border-radius:100%;
  }
  
  @-webkit-keyframes rotation {
     from {-webkit-transform: rotate(0deg);}
     to {-webkit-transform: rotate(359deg);}
  }
  @-moz-keyframes rotation {
     from {-moz-transform: rotate(0deg);}
     to {-moz-transform: rotate(359deg);}
  }
  @-o-keyframes rotation {
     from {-o-transform: rotate(0deg);}
     to {-o-transform: rotate(359deg);}
  }
  @keyframes rotation {
     from {transform: rotate(0deg);}
     to {transform: rotate(359deg);}
    }

    .loader {
      background: #fff;
      height: 100vh;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      overflow: hidden;
      z-index: 4;
      background-color: rgb(255 255 255 / 50%);
    }

    .word-for-confirm {
      text-align: center;
      color: #fff;
      font-size: 30px;
      font-weight: 700;
    }

}

@media screen and (min-width: 1296px) {
  .header__nav {
    max-width: 1296px;
  }

  .header__navbar {
    max-width: 1296px;
    margin-top: 20px;
    height: 100px;
    border-radius: 30px;
    background-image: url('../assets/icon/header.svg');
  }

  .hamburger {
    display: none;
  }

  .header__menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .header__menu-list {
    margin-top: 0;
    flex-direction: unset;
    margin-right: 368px;
    align-items: center;
    padding-left: unset;
    width: 358px;
  }

  .header__logo {
    font-size: 18px;
    font-weight: 800;
    padding-left: 38px;
  }

  .header__menu-item {
    padding-bottom: unset;
    font-size: 16px;
    font-weight: 400;
  }

  .header__menu-button-list {
    flex-direction: unset;
    padding: unset;
    margin-right: 34px;
  }

  .header__menu-button {
    margin-bottom: unset;
    margin-left: 9px;
    padding: 10px 25px;
  }

  .header__menu-button--one {
    padding: 10px 35px;
  }

  .intro__wrapper {
    max-width: 1296px;
    background-image: url('../assets/icon/intro.svg');
    margin-top: 20px;
    flex-direction: unset;
    border-radius: 30px;
  }

  .intro__mini-wrapper-one {
    width: unset;
    padding-left: 216px;
  }

  .intro__title {
    padding-top: 121px;
    text-align: left;
    font-size: 46px;
    max-width: 575px;
  }

  .intro__description {
    padding-top: 15px;
    font-size: 20px;
    text-align: left;
    max-width: 575px;
  }

  .intro__button-wrapper {
    display: flex;
    justify-content: space-between;
    padding-top: 69px;
    padding-bottom: 86px;
    max-width: 575px;
  }

  .intro__button {
    margin-bottom: unset;
    padding: 12px 46px;
  }

  .intro__mini-wrapper-two {
    margin-right: 207px;
    margin-top: 210px;
  }

  .introCard {
    margin-top: 36px;
  }

  .introCard__wrapper {
    max-width: 1296px;
  }

  .introCard__list {
    flex-direction: unset;
  }

  .introCard__item {
    max-width: 416px;
    margin-bottom: unset;
    background-size: cover;
  }

  .welkom {
    margin-top: 115px;
  }

  .welkom__wrapper {
    padding-left: unset;
    max-width: 1296px;
    position: relative;
  }

  .welkom__wrapper::before {
    content: '';
    position: absolute;
    top: 10px;
    right: 0;
    width: 856px;
    height: 314px;
    background-image: url('../assets/icon/welkom.svg');
    background-repeat: no-repeat;
    background-size: cover;
  }

  .welkom__title {
    font-size: 30px;
    padding-bottom: 15px;
    max-width: 417px;
    width: 100%;
  }

  .welkom__copiright-wrapper {
    max-width: 417px;
    width: 100%;
  }

  .welkom__copiright {
    padding-bottom: 30px;
  }

  .welkom__logo {
    display: none;
  }

  .opdrachtgevers {
    margin-top: 66px;
  }

  .opdrachtgevers__wrapper {
    margin-top: unset;
    max-width: 1296px;
  }

  .opdrachtgevers__title {
    margin-bottom: 48px;
    font-size: 30px;
  }

  .opdrachtgevers__list {
    display: flex;
    justify-content: space-between;
    margin-bottom: 44px;
  }

  .opdrachtgevers__item {
    padding: 22px 23px 236px 20px;
    max-width: 306px;
    width: 100%;
  }

  .opdrachtgevers__button {
    max-width: 346px;
    margin: 0 auto;
  }

  .opdrachtgevers__item-two .opdrachtgevers__list-copiright,
  .opdrachtgevers__item-four .opdrachtgevers__list-copiright {
    max-width: 230px;
  }

  .support {
    margin-top: 243px;
    margin-bottom: 186px;
  }

  .support__wrapper {
    max-width: 1076px;
    background-image: url('../assets/icon/support.svg');
    background-size: cover;
    border-radius: 30px;
    padding: 60px 250px 49px 250px;
  }

  .support__title {
    font-size: 30px;
    margin-bottom: 19px;
  }

  .support__description {
    font-size: 25px;
    margin-bottom: 44px;
  }

  .support__button {
    font-size: 20px;
  }

  .footer__wrapper {
    max-width: 1296px;
    width: 100%;
    margin: 0 auto;
    border-top: unset;
    display: flex;
    justify-content: space-between;
    margin-top: 44px;
    margin-bottom: 118px;
  }

  .footer {
    border-top: 3px solid #32AADD;
  }

  .footer__logo-wrapper {
    width: unset;
    max-width: unset;
    margin: unset;
    align-items: unset;
    display: unset;
    justify-content: unset;
    margin-top: unset;
    margin-bottom: unset;
  }

  .footer__logo {
    font-size: 18px;
    font-weight: 900;
  }

  .footer__menu-item,
  .footer__info-item {
    font-size: 16px;
    color: #000;
    margin-bottom: 5px;
  }

  .footer__button {
    color: #fff;
    padding: 12px 83px;
    background: #253D7A;
    border-radius: 61px;
    font-size: 20px;
  }

  .footer__logo-wrapper {
    width: 122px;
  }

  .footer__menu-wrapper {
    width: 90px;
  }

  .footer__info-wrapper {
    width: 176px;
    margin-right: 468px;
  }

  .registration__logo-wrapper {
    display: none;
  }

  .registration__common-bg-wrapper {
    max-width: 1296px;
    display: flex;
    align-items: flex-start;
  }

  .registration__mini--wrapper .intro__mini-wrapper-two {
    margin-right: 0;
  }

  .registration__common-bg-wrapper {
    padding-top: 120px;
  }

  .registration__big-title {
    font-size: 46px;
    margin-bottom: 21px;
  }

  .registration__tabs-wrapper {
    max-width: 244px;
  }

  .registration__big-wrapper {
    max-width: 442px;
    margin-top: 0;
    width: 100%;
  }

  .registration__mini--wrapper {
    margin-right: 358px;
  }

  .registration__input-wrapper {
    margin-bottom: 25px;
  }

  .registration__copiright {
    max-width: 341px;
    margin: 0 auto;
    margin-top: 15px;
  }

  .registration__wrapper {
    height: 100vh;
    background: url('../assets/icon/registration.svg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .registration__common-wrapper {
    background: unset;
  }

  .registration__copiright span {
    color: unset;
  }

  .registration__input-error {
    position: absolute;
    right: -132px;
    top: 14px;
    font-size: 16px;
    color: #FF7676;
    left: unset;
  }

  .registration__input-password-error {
    position: absolute;
    right: -232px;
    top: 4px;
    font-size: 16px;
    color: #FF7676;
    max-width: 212px;
    left: unset;
  }

  .registration__input-error-border {
    border-color: #FF7676;
  }

  .qwestFilter__click-wrapper {
    display: none;
  }

  .qwestFilter__mini-wrapper {
    display: unset;
  }

  .qwestFilter__wrapper {
    margin-top: 0;
    max-width: 416px;
  }

  .filter__for-desktop-fitch {
    max-width: 1296px;
    margin: 0 auto;
    display: flex;
    margin-top: 160px;
  }

  .qwestFilter__mini-wrapper {
    padding: 27px 22px;
  }

  .qwestion__wrapper {
    max-width: unset;
    padding-left: 24px;
  }

  .qwestion {
    width: 100%;
  }

  .qwestion__item {
    max-width: 416px;
  }

  .qwestion__list {
    margin-top: 0;
    flex-direction: unset;
    flex-wrap: wrap;
  }

  .qwestFilter {
    width: 100%;
    max-width: 416px;
    padding: 27px 22px;
    border: 1px solid #33ADE0;
    border-radius: 30px;
    height: 1302px;
  }

  .qwestion__item-more-button {
    margin-bottom: 300px;
  }

  .qwestion__title-wrapper {
    margin-right: 144px;
  }

  .qwestion__item-body {
    padding: 13px 22px 10px 22px;
  }

  .qwestion__item-header {
    padding: 17px 22px 14px 22px;
  }

  .thanks__wrapper {
    max-width: 1296px;
    padding-bottom: 106px;
  }

  .thanks__wrapper img {
    width: 1296px;
    height: 138px;
}

.thanks__title {
  font-size: 46px;
  padding-top: 79px;
  font-weight: 700;
}

.thanks__copiright {
  font-size: 20px;
  font-weight: 400;
  padding-top: 15px;
}

.intro__mini-wrapper-thanks {
  margin-top: 79px;
}

.intro__mini-wrapper-thanks {
  margin: 0 auto;
  margin-top: 79px;
}

.thanks__wrapper-policy {
  max-width: 1296px;
  font-size: 16px;
  font-weight: 400;
}

}